export default function () {

	const expandBufferBox = document.querySelectorAll('.c-buffer-box-expand');

	if (!expandBufferBox) return;
	
	expandBufferBox.forEach((instance, index) => {
    index++;
    const contentContainer = instance.querySelector('.c-buffer-box-expand__text');
    const showMoreButton = instance.querySelector('.c-buffer-box-expand__button');
    const showMoreText = instance.querySelector('.c-buffer-box-expand__button-text');
    const contentContainerHeight = contentContainer.offsetHeight;
    contentContainer.style.height = '0px';

		var contentContainerId = 'c-buffer-box-expand-' + index;
		contentContainer.setAttribute('id', contentContainerId);

    showMoreButton.setAttribute('aria-expanded', false);
		showMoreButton.setAttribute('aria-controls', contentContainerId);

    // Show more click
		showMoreButton.addEventListener('click', (e) => {
			e.preventDefault();

			if (showMoreButton.getAttribute('aria-expanded') === 'false') {
				showMoreButton.setAttribute('aria-expanded', true);
				instance.classList.add('is-expanded');
        showMoreText.textContent = 'Läs mindre';
        contentContainer.style.height = contentContainerHeight + 'px';
				contentContainer.focus();
			}
			else {
				showMoreButton.setAttribute('aria-expanded', false);
				instance.classList.remove('is-expanded');
        showMoreText.textContent = 'Läs mer';
        contentContainer.style.height = '0px';
			}
		});

  });
}