/**
 * Check if an object is iterable
 * @param {object} obj The object to check
 * @return {bool}
 */
export default function (obj) {
	if (obj === null) {
		return false;
	}
	return typeof obj[Symbol.iterator] === 'function';
}