/**
 * Truncate
 */

import icon from '../icon/components.icon-es6.js';

export default function () {

	if (!document.querySelector('[data-module="truncate"]')) {
		return;
	}

	const truncate = document.querySelectorAll('[data-module="truncate"]');
	let i = 0;

	truncate.forEach((instance) => {
		i++;
		const rows = parseInt(instance.dataset.truncateVisibleRows);
		const buttonText = instance.dataset.truncateButton;
		const buttonTextTruncated = instance.dataset.truncatedButton;
		const content = instance.innerHTML;
		const computedInstance = getComputedStyle(instance);
		const contentHeight = instance.clientHeight - parseFloat(computedInstance.paddingBottom);
		const lineHeight = parseFloat(computedInstance.lineHeight);
		const numberOfRows = contentHeight / lineHeight;

		/*
			Check if total number of rows is less than truncate value + 10, if so don't truncate.
			But if contentHeight is 0 (or less probably) that means the truncate content is hidden and we can't get the proper element height, so instead disable the check and always init truncate for those instances.
		*/

		if (contentHeight > 0) {
			if (Math.floor(numberOfRows) <= rows) {
				instance.classList.add('not-truncated');
				return;
			}
		}

		// Clear content and append later
		instance.innerHTML = '';

		// Create a content container
		const contentContainer = document.createElement('div');
		contentContainer.classList.add('c-truncate__content');
		var contentContainerId = 'c-truncate__content-' + i;
		contentContainer.setAttribute('id', contentContainerId);
		contentContainer.setAttribute('tabindex', '-1');
		contentContainer.innerHTML = content;
		instance.appendChild(contentContainer);

		// Create the button to show content
		const showMoreButton = document.createElement('button');
		showMoreButton.type = 'button';
		showMoreButton.classList.add('c-truncate__show', 'c-button', 'c-button--link');
		showMoreButton.setAttribute('aria-expanded', false);
		showMoreButton.setAttribute('aria-controls', contentContainerId);
		const textElement = document.createElement('span');
		textElement.innerHTML = buttonText;
		showMoreButton.appendChild(textElement);
		showMoreButton.appendChild(icon({
			icon: '/ui-project/components/icon/icons/arrow-small-down.svg',
			extra_class: [
				'c-icon--xs', 'c-icon--ml', 'c-truncate__button-icon'
			]
		}));

		// Set truncate state
		instance.classList.add('is-truncated');

		// Set rows to truncate content
		instance.style.setProperty('--truncate-height', rows);

		// Append show button
		instance.appendChild(showMoreButton);

		// Show more click
		showMoreButton.addEventListener('click', (e) => {
			e.preventDefault();

			if (showMoreButton.getAttribute('aria-expanded') === 'false') {
				instance.classList.remove('is-truncated');
				showMoreButton.setAttribute('aria-expanded', true);
				textElement.innerHTML = buttonTextTruncated;
				contentContainer.focus();
			}
			else {
				instance.classList.add('is-truncated');
				showMoreButton.setAttribute('aria-expanded', false);
				textElement.innerHTML = buttonText;
			}
		});
	});
}
