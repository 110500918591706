import extend from '../../js/utilities/extend/u-extend.js';
import ajax from '../../js/utilities/ajax/u-ajax.js';

/**
 * Fetch markup from svg-file
 * @param  {[Object]} settings [User settings]
 * @return {[Object]}          [SVG wrapper element]
 */
export default function (settings) {

	// Default settings
	const defaults = {
		icon: false,
		size: false,
		extra_class: false // Space-separated string or array
	};

	// Merge settings
	settings = extend(defaults, settings);

	// Create wrapper element
	const wrapper = document.createElement('span');

	// Add main class to wrapper
	wrapper.classList.add('c-icon');

	// Add size modifier
	if (settings.size) {
		wrapper.classList.add(`c-icon--${settings.size}`);
	}

	// Add extra class(es) - note that classList.add wants a comma-separated list
	// If the given extra class is a string, trim it, replace multiple spaces, then split on space
	if (typeof settings.extra_class === 'string') {
		settings.extra_class = settings.extra_class.trim().replace(/\s+/g, ' ').split(' ');
	}
	// Make sure that we’re now dealing with an array, then spread it
	if (Array.isArray(settings.extra_class)) {
		wrapper.classList.add(...settings.extra_class);
	}

	// Set accessibility attributs
	wrapper.setAttribute('aria-hidden', true);

	// Set up Ajax-request to get the svg
	const request = ajax({
		url: settings.icon
	});

	// Send Ajax-request
	request.then((response) => {
		// Insert SVG to wrapper element and add role-attribute
		wrapper.innerHTML = response;
	}).catch(() => {
		// Console warn on error
		console.warn(`${settings.icon} was not found...`);
	});

	// Return wrapper element
	return wrapper;
}