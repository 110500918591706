import convertToButton from '../../utilities/convert-to-button/u-convert-to-button.js';
import '../../js/vendor/polyfill/polyfill-closest.js';

export default function () {
  
  const iframeLink = document.querySelector('[data-iframe-popup-button]');
  const iframePopup = document.querySelector('.c-iframe-popup');
  const iframeWrapper = document.querySelector('.c-iframe-popup__wrapper');

  if(!iframeLink || !iframePopup || !iframeWrapper) 
    return;

  let iframeButton = iframeLink;
  if (!iframeLink.matches('button')) {
    iframeButton = convertToButton(iframeLink);
  }

  const iframeDataHref = iframeButton.getAttribute('data-href');
  const iframeDataTitle = iframeButton.getAttribute('data-iframe-title');

  if(!iframeDataHref)
    return;

  const iframeElement = document.createElement("iframe");
  iframeElement.setAttribute("src", iframeDataHref);
  iframeElement.setAttribute("title", iframeDataTitle);
  iframeElement.setAttribute("frameborder", "0");
  iframeElement.setAttribute("height", "100%");
  iframeElement.setAttribute("width", "100%");

  iframeButton.setAttribute('aria-controls', iframePopup.getAttribute('id'));
  iframeButton.setAttribute('aria-expanded', false);
  iframePopup.setAttribute('aria-hidden', true);

  function closePopup() {
    document.querySelector('body').style.overflow = "visible";
    iframePopup.classList.remove('c-iframe-popup--show');
    iframeButton.setAttribute('aria-expanded', false);
    iframePopup.setAttribute('aria-hidden', true);
    iframeElement.parentNode.classList.remove('c-iframe-popup__wrapper--show');
    iframePopup.focus();

    window.removeEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        closePopup();
      }
    });
    history.replaceState("", document.title, window.location.pathname);
  }

  function showIframe() {
    document.querySelector('body').style.overflow = "hidden";
    iframePopup.classList.remove('c-iframe-popup--loading');

    //Animation hack
    setTimeout(function() {
      iframeElement.parentNode.classList.add('c-iframe-popup__wrapper--show');
      iframePopup.focus();
    }, 200);

    window.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') {
        closePopup();
      }
    });
    window.location.hash="#sample";
  }

  let iframeLoaded = false;

  
  function iframeButtonClick() {
    if(!iframePopup.classList.contains('c-iframe-popup--show')) {
      iframeWrapper.appendChild(iframeElement);
      iframePopup.classList.add('c-iframe-popup--show');
      iframePopup.classList.add('c-iframe-popup--loading');
      iframeButton.setAttribute('aria-expanded', true);
      iframePopup.setAttribute('aria-hidden', false);

      if(!iframeLoaded) {
        document.querySelector('.c-iframe-popup iframe').addEventListener("load", function() {
          showIframe();
          iframeLoaded = true;
        });
      } else {
        showIframe();
      }
    }
  };

  iframeButton.addEventListener('click', iframeButtonClick)

  var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if(width > 1024) {
    window.addEventListener('click', function(e){   
      if (document.querySelector('.c-iframe-popup__wrapper') !== e.target && e.target !== iframeButton){
        if(iframeElement.parentNode && iframeElement.parentNode.classList.contains('c-iframe-popup__wrapper--show')) {
          closePopup();
        }
      }
    });
  } else {
    document.querySelector('.c-iframe-popup__close').addEventListener('click', function(e){   
      closePopup();
    });
  }

  if (window.location.hash === "#sample") {
    iframeButtonClick()
  }

  window.onhashchange = function () {
    if (location.hash === "#sample") {
      iframeButtonClick()
    }
  };
}