// Import a polyfill for nodeList.forEach, eg. document.querySelectorAll('.foo').forEach()
import '../../vendor/polyfill/polyfill-nodelist-foreach.js';

import Dialog from '../../../components/dialog/components.dialog.js';
import convertToButton from '../convert-to-button/u-convert-to-button.js';

export default function (selector = '.open-in-dialog') {
	document.querySelectorAll(selector).forEach(link => {
		let dialog;
		let button = convertToButton(link, 't-button-as-text t-button-as-text--link');

		// Make clicking the button open the dialog
		button.addEventListener('click', function () {
			// Create a new Dialog based on the link’s href
			if (button.hasAttribute('data-href')) {
				const href = button.getAttribute('data-href');
				let dialogContent;

				// If the href seems like a fragment identifier, try to find an element with a matching id
				if (href.match(/^#/)) {
					dialogContent = document.querySelector(href);
					if (dialogContent) {
						dialog = new Dialog({
							title: button.hasAttribute('data-dialog-title') ? button.getAttribute('data-dialog-title') : button.textContent,
							content: dialogContent.innerHTML
						});
						dialog.open();
					}
					else {
						console.warn(`No element with the id ${href} found.`);
					}
				}
				// Otherwise, try loading the href with Ajax
				else {
					var xhr = new XMLHttpRequest();
					xhr.onreadystatechange = function () {
						if (xhr.readyState === 4) {
							if (xhr.status === 200) {

								// Create a temporary element to insert the response into so we can parse the DOM
								const temp = document.createElement('html');
								temp.innerHTML = xhr.responseText;

								// If we received a full HTML document, use the body content if available, otherwise the full response
								let dialogContent = temp.querySelector('body') ? temp.querySelector('body').innerHTML : temp.innerHTML;

								// If the href ends in a fragment identifier, try to find a matching element
								if (href.match(/(#.+)$/)) {
									let target = temp.querySelector(href.match(/(#.+)$/)[1]);
									if (target) {
										dialogContent = target.innerHTML;
									}
									else {
										console.warn(`No element with the id ”${href.match(/#(.+)$/)[1]}” found. Using the full body of the response as a fallback.`);
									}
								}

								// Create and open the dialog
								dialog = new Dialog({
									title: button.hasAttribute('data-dialog-title') ? button.getAttribute('data-dialog-title') : button.textContent,
									content: dialogContent
								});
								dialog.open();
							}
							else {
								console.warn('Error: ' + xhr.status);
							}
						}
					};
					xhr.open('GET', href);
					xhr.send(null);
				}
			}
			else {
				dialog = new Dialog({
					title: 'Empty dialog',
					content: 'No dialog content found'
				});
				dialog.open();
			}

			// Log click data-tm-event-title
			if (button.hasAttribute('data-tm-event-name')) {
				let tmEventTitle = 'Ej identifierad';

				if (button.hasAttribute('data-tm-event-title'))
				{
					tmEventTitle = button.getAttribute('data-tm-event-title');
				}

				const tmEventName = button.getAttribute('data-tm-event-name');
				
				window.pushToLocalDataLayer({
					'event': tmEventName,
					'event_key': tmEventTitle,
				});
			}
		});
	});
}