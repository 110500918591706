import Collapse from '../../js/utilities/collapse/u-collapse.js';
import extend from '../../js/utilities/extend/u-extend.js';

export default function (accordions) {
	accordions.forEach((accordion) => {
		let settings = {
			element: accordion,
			selectors: {
				header: '.c-accordion__trigger',
				content: '.c-accordion__content'
			},
			classNames: {
				expanded: 'c-accordion--is-expanded',
				collapsed: 'c-accordion--is-collapsed',
				button: 'c-accordion__button',
				buttonText: 't-visually-hidden',
				iconExtraClass: 'c-accordion__icon'
			},
			strings: {
				show: 'Visa innehåll',
				hide: 'Dölj innehåll'
			},
			icons: '/ui-project/components/icon/icons/arrow-small-down.svg',
			on: {
				init: (context) => {
					if (!document.querySelector('.c-accordion__wrapper')) return;
					const chars = {'å':'a','ä':'a','ö':'o','é':'e','ü':'u','¡':'','!':'',',':'','?':''};
					const parsedButtonText = context.buttonText.innerText.toLowerCase().replace(/\s/g,'').replace(/[åäöéü¡!,?]/g, m => chars[m]);
					if (location.hash) {
						if (location.hash === '#' + parsedButtonText) {
							context.expand();
							const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
							// const yOffset = viewportWidth > 1024 ? document.querySelector('.c-header__main-navigation').offsetHeight : viewportWidth > 768 ? document.querySelector('.c-header-mobile__inner').offsetHeight : document.querySelector('.c-header-mobile').offsetHeight;
							accordion.style.scrollMargin = '15px';
							setTimeout(() => {
								accordion.scrollIntoView({behavior: "smooth"});
							}, 200);
						} else if (context.content.querySelector(location.hash)) {
							context.id = true;
							context.expand();
						}
					}
				},
				expand: (context) => {
					if (!document.querySelector('.c-accordion__wrapper') || context.id) return;
					const chars = {'å':'a','ä':'a','ö':'o','é':'e','ü':'u','¡':'','!':'',',':'','?':''};
					window.location.hash = context.buttonText.innerText.toLowerCase().replace(/\s/g,'').replace(/[åäöéü¡!,?]/g, m => chars[m]); 
				},
				collapse: (context) => { 
					if (!document.querySelector('.c-accordion__wrapper')) return;
					context.id = false;
					history.replaceState("", document.title, window.location.pathname); 
				}
			}
		};

		if (accordion.getAttribute('data-js-accordion-settings')) {
			settings = extend(settings, JSON.parse(accordion.getAttribute('data-js-accordion-settings')));
		}

		new Collapse(settings);
	});
}
