/**
 * ES6 JS-module translation of https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/
 *
 * Merge objects together
 * @param  {mixed} args
 * @return {Object} merged object
 */
const extend = function (...args) {

	// Variables
	let extended = {};
	let deep 	 = false;
	let i 		 = 0;
	const length = args.length;

	// Check if a deep merge
	if (Object.prototype.toString.call(args[0]) === '[object Boolean]') {
		deep = args[0];
		i++;
	}

	/**
	 * Merge the object into the extended object
	 * @param  {Object} obj
	 */
	const merge = function (obj) {
		for (let prop in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, prop)) {
				// If deep merge and property is an object, merge properties
				if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
					extended[prop] = extend(true, extended[prop], obj[prop]);
				}
				else {
					extended[prop] = obj[prop];
				}
			}
		}
	};

	// Loop through each object and conduct a merge
	for (; i < length; i++) {
		let obj = args[i];
		merge(obj);
	}

	return extended;

};

export default extend;