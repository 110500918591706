const throttle = function (fn, th, scope) {
	const threshhold = th || 250;
	let last;
	let deferTimer;

	return function () {
		const context = scope || this;
		const now = +new Date();
		const args = arguments;

		if (last && now < last + threshhold) {
			// hold on to it
			clearTimeout(deferTimer);
			deferTimer = setTimeout(function () {
				last = now;
				fn.apply(context, args);
			}, threshhold);
		}
		else {
			last = now;
			fn.apply(context, args);
		}
	};
};

export default throttle;