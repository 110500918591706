// Import a polyfill for nodeList.forEach, eg. document.querySelectorAll('.foo').forEach()
import '../../js/vendor/polyfill/polyfill-nodelist-foreach.js';

import isIterable from '../../js/utilities/is-iterable/u-is-iterable.js';
import throttle from '../../js/utilities/throttle/u-throttle.js';

// An array to store all scrollable elements
const scrollableElements = [];

// Add scrollable elements
function scrollableElement (elements) {
	if (!isIterable(elements)) {
		elements = [elements];
	}
	for (const element of elements) {
		if (scrollableElements.indexOf(element) === -1) {
			scrollableElements.push(element);
		}
	}
}

// Check widths and add or remove scrolling
function checkWidths () {

	function updateShadows (scrollingElement, element) {
		const shadowElement = scrollingElement.parentNode;

		if (scrollingElement.scrollLeft + 1 >= element.clientWidth - scrollingElement.clientWidth) {
			shadowElement.classList.add('c-scrollable-element--scrolled-right');
		}
		else {
			shadowElement.classList.remove('c-scrollable-element--scrolled-right');
		}

		if (scrollingElement.scrollLeft === 0) {
			shadowElement.classList.add('c-scrollable-element--scrolled-left');
		}
		else {
			shadowElement.classList.remove('c-scrollable-element--scrolled-left');
		}
	}

	// For each element, check if it is wider than its parent.
	// If it is, wrap it in containers to allow for horizontal scrolling.
	scrollableElements.forEach(element => {

		if (element.clientWidth > element.parentNode.clientWidth) {
			if (!element.classList.contains('is-scrollable')) {
				const outerWrapper = document.createElement('div');
				outerWrapper.className = 'c-scrollable-element';
				const innerWrapper = document.createElement('div');
				innerWrapper.className = 'c-scrollable-element__inner';
				outerWrapper.appendChild(innerWrapper);
				element.parentNode.insertBefore(outerWrapper, element);
				innerWrapper.appendChild(element);
				element.classList.add('is-scrollable');
				element.outerWrapper = outerWrapper;
				element.innerWrapper = innerWrapper;

				// Update shadows when the container element scrolls
				element.parentNode.addEventListener('scroll', e => {
					updateShadows(e.target, element);
				});

				// Also update shadows immediately to reflect the initial scroll state
				updateShadows(innerWrapper, element);
			}
		}
		else if (element.classList.contains('is-scrollable')) {
			element.classList.remove('is-scrollable');
			element.outerWrapper.parentNode.replaceChild(element, element.outerWrapper);
		}
	});

}

// Update scrollability on various events
['newcontent', 'load', 'resize', 'orientationchange'].forEach(e => {
	window.addEventListener(e, throttle(checkWidths, 400));
});

export default scrollableElement;
