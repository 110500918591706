/* globals dialogPolyfill */
import Component from '../../js/core/component.js';
import generateRandomId from '../../js/utilities/random-id/u-random-id.js';
import focusableElementSelector from '../../js/utilities/focusable-elements/u-focusable-elements.js';
import getSiblings from '../../js/utilities/get-siblings/u-get-siblings.js';

// Import a polyfill for node.remove() (IE)
import '../../js/vendor/polyfill/polyfill-remove.js';

// Import https://github.com/GoogleChrome/dialog-polyfill for browsers without native dialog support
import './_vendor/dialog-polyfill.js';

/**
 * Dialog Class
 */
class Dialog extends Component {

	/**
	 * Default settings for this component
	 * @return {object} Default settings
	 */
	_defaultSettings () {
		return {
			debug: false,
			id: 'dialog',
			classNames: {
				dialog: 		'c-dialog',
				document: 	'c-dialog__document',
				title: 			'c-dialog__title',
				close: 			'c-dialog__close',
				content: 		'c-dialog__content'
			},
			strings: {
				close: 'Stäng dialog',
				title: 'Dialog'
			},
			closeButtonIcon: '<svg role="presentation"><use xlink:href="/ui-project/_build/icons.svg#icon-close"></use></svg>',
			showCloseButtonText: false,
			title: null,
			hideTitle: false,
			content: '',
			on: {
				init: () => {},
				open: () => {},
				close: () => {}
			}
		};
	}

	/**
	 * Initialize Dialog
	 */
	_init () {
		this._log('_init()');
		this._log(this.settings);

		this.lastFocus = null;

		// Create dialog markup
		this._create();

		this.dialog.addEventListener('close', () => {
			this.close();
		});

		// Assign our keypresshandler-function to a property
		this.keyPressHandler = this._keyPress.bind(this);

		// Append dialog to body
		document.querySelector('body').appendChild(this.dialog);

		this.settings.on.init(this);
	}

	/**
	 * Create Dialog dom elements
	 */
	_create () {
		this._log('_create()');

		// Create dialog element
		this.dialog = document.createElement('dialog');

		// Set class to dialog
		this.dialog.className = this.settings.classNames.dialog;

		// Set ID to dialog
		if (this.settings.id === false) {
			generateRandomId(this.dialog, false, 'dialog-');
		}
		else {
			this.dialog.id = this.settings.id;
		}
		this.titleId = this.settings.id + '-title';

		// Set aria-label if the title should be visually hidden
		if (this.settings.hideTitle) {
			this.dialog.setAttribute('aria-label', this.settings.title);
		}
		else {
			this.dialog.setAttribute('aria-labelledby', this.titleId);
		}

		// Create and insert an element with role="document"
		this.documentElement = document.createElement('div');
		this.documentElement.setAttribute('role', 'document');
		this.documentElement.className = this.settings.classNames.document;
		this.dialog.appendChild(this.documentElement);

		// Create and insert the close button
		this.closeButton = document.createElement('button');
		this.closeButton.className = this.settings.classNames.close;
		this.closeButton.setAttribute('type', 'button');
		if (this.settings.closeButtonIcon) {
			this.closeButton.innerHTML = this.settings.closeButtonIcon;
		}
		if (this.settings.showCloseButtonText) {
			this.closeButton.appendChild(document.createTextNode(this.settings.strings.close));
		}
		else {
			this.closeButton.setAttribute('aria-label', this.settings.strings.close);
		}
		this.closeButton.addEventListener('click', () => {
			this.dialog.close();
		});
		this.documentElement.appendChild(this.closeButton);

		// Create and insert the title unless it should be visually hidden
		if (!this.settings.hideTitle) {
			this.dialogTitle = document.createElement('h1');
			this.dialogTitle.className = this.settings.classNames.title;
			this.dialogTitle.setAttribute('id', this.titleId);
			this.dialogTitle.appendChild(document.createTextNode(this.settings.title ? this.settings.title : this.settings.strings.dialog));
			this.documentElement.appendChild(this.dialogTitle);
		}

		// Create and insert the content container
		this.dialogContent = document.createElement('div');
		this.dialogContent.className = this.settings.classNames.content;
		this.documentElement.appendChild(this.dialogContent);

		// If content is an object, assume it is a node, otherwise assume an HTML string
		if (typeof this.settings.content === 'object') {
			this.dialogContent.appendChild(this.settings.content);
		}
		else {
			this.dialogContent.innerHTML = this.settings.content;
		}

		// Load polyfill if needed
		this._polyfill();
	}

	/**
	 * Initialize Dialog-element polyfill if the browser dosen't support the native element
	 */
	_polyfill () {
		// Init Dialog Polyfill if the dialog-element isn't supported by the browser
		if (typeof this.dialog.showModal !== 'function' && typeof dialogPolyfill !== 'undefined') {
			dialogPolyfill.registerDialog(this.dialog);
		}
	}

	/**
	 * Get the first focusable element
	 * @return {nodelist}
	 */
	_getFirstFocusableElement () {
		return this.dialog.querySelector(focusableElementSelector);
	}

	/**
	 * Get all focusable elements
	 * @return {nodelist}
	 */
	_getFocusableElements () {
		return this.dialog.querySelectorAll(focusableElementSelector);
	}

	/**
	 * Keypress event handler
	 */
	_keyPress (event) {
		if (!this.isOpen()) {
			return;
		}

		if (event.which === 9) {
			this._trapFocus(event);
		}
	}

	/**
	 * Trap focus when using tab / shift+tab
	 * @param  {event} event
	 */
	_trapFocus (event) {
		var focusableElements = this._getFocusableElements();

		// If the focus is at the first element and we want to focus back, set focus to the last element
		if (event.shiftKey && document.activeElement === focusableElements[0]) {
			focusableElements[focusableElements.length - 1].focus();
			event.preventDefault();
		}
		// If the focus is at the last element and we want to focus forward, set focus to the first element
		else if (!event.shiftKey && document.activeElement === focusableElements[focusableElements.length - 1]) {
			focusableElements[0].focus();
			event.preventDefault();
		}
		// If the dialog dosen't have any focusable element, just prevent tabbing
		else if (focusableElements.length < 1) {
			event.preventDefault();
		}
	}

	/**
	 * Hide sibling elements from screen readers
	 */
	_hideSiblings () {
		getSiblings(this.dialog).forEach(target => {
			// Save the original value if the element already has an aria-hidden attribute
			const original = target.getAttribute('aria-hidden');
			if (original) {
				target.setAttribute('data-aria-hidden-original', original);
			}
			target.setAttribute('aria-hidden', 'true');
		});
	}

	/**
	 * Restore the aria-hidden attribute of sibling elements
	 */
	_restoreSiblings () {
		getSiblings(this.dialog).forEach(target => {
			const original = target.getAttribute('data-aria-hidden-original');
			if (original) {
				target.setAttribute('aria-hidden', original);
				target.removeAttribute('data-aria-hidden-original');
			}
			else {
				target.removeAttribute('aria-hidden');
			}
		});
	}

	/**
	 * Set focus to correct element when open the dialog
	 */
	_setOpenFocus () {
		let firstFocusableElement = this._getFirstFocusableElement();

		// If there is some focusable elements inside the dialog, then set focus to the first element
		if (firstFocusableElement) {
			firstFocusableElement.focus();
		}
		// If there isn't any focusable elements inside the dialog, then set focus to the dialog element
		else {
			this.dialog.focus();
		}
	}

	/**
	 * Public methods
	 */

	/**
	 * Show dialog
	 */
	open () {
		this._log('open()');

		// Save last focus
		this.lastFocus = document.activeElement;

		// Open modal
		if (!this.isOpen()) {
			this.dialog.showModal();
		}

		// Handle focus when open
		this._setOpenFocus();

		// Trap focus
		document.addEventListener('keydown', this.keyPressHandler, true);

		// Hide all element outside the dialog
		this._hideSiblings();

		// Run dialog open callback
		this.settings.on.open(this);
	}

	/**
	 * Hide the dialog
	 */
	close () {
		this._log('close()');

		// Close the dialog
		if (this.isOpen()) {
			this.dialog.close();
		}

		// Restore focus
		this.lastFocus.focus();

		// Remove the focus trap
		document.removeEventListener('keydown', this.keyPressHandler, true);

		// Restore all elements outside the dialog
		this._restoreSiblings();

		// Run the dialog close callback (if any)
		this.settings.on.close(this);

		// Remove the dialog element from the DOM
		this.dialog.remove();
	}

	/**
	 * Is the dialog open or not
	 * @return {Boolean}
	 */
	isOpen () {
		return this.dialog.getAttribute('open') !== null;
	}
}

export default Dialog;