const dashboardHeroTime = document.querySelectorAll('.c-weatherdate__time');

function refreshTime() {
	var dateString = new Date().toLocaleTimeString('sv-SE',);
	var formattedString = dateString.replace(', ', ' - ');
	dashboardHeroTime.forEach((item) => {
		item.innerHTML = formattedString;
	});
}

setInterval(refreshTime, 1000);