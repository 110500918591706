/**
 * Replace a link with a button
 * @param link A DOM node
 * @param classNames A space separated list of class names to add
 */
export default function (link, classNames) {
	if (link.nodeName.toLowerCase() !== 'a') {
		console.warn('You supplied a non-link to convert into a button:', link);
		return;
	}

	const button = document.createElement('button');
	button.setAttribute('type', 'button');
	button.innerHTML = link.innerHTML;
	if (link.hasAttribute('title')) {
		button.setAttribute('title', link.getAttribute('title'));
	}
	if (link.hasAttribute('class')) {
		button.setAttribute('class', link.getAttribute('class'));
	}
	if (classNames) {
		// Convert the classNames string into an array and loop through it to add each className
		classNames.split(/\s+/).forEach(className => button.classList.add(className));
	}
	if (link.hasAttribute('href')) {
		button.setAttribute('data-href', link.getAttribute('href'));
	}

	// Copy all data attributes
	let attributes = link.attributes;
	for (let i = attributes.length - 1; i >= 0; i--) {
		if (attributes[i].name.match(/^data-/i)) {
			button.setAttribute(attributes[i].name, attributes[i].value);
		}
	}

	// Replace the link with the button
	link.parentNode.replaceChild(button, link);

	return button;
}