/**
 * Get all sibling elements
 * @return {array}
 */
export default function (node) {
	const nodes = Array.prototype.slice.call(node.parentNode.childNodes);
	const siblings = nodes.filter(function (node) {
		return node.nodeType === 1;
	});
	siblings.splice(siblings.indexOf(node), 1);
	return siblings;
}
