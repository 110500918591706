import '../../../js/vendor/polyfill/polyfill-custom-event.js';
import scrollableElement from '../../scrollable-element/components.scrollable-element.js';
import ResponsiveTable from '../../responsive-table/components.responsive-table.js';

document.addEventListener('newcontent', (e) => {
	const context = e.target;

	// Make tables scrollable if needed
	scrollableElement(context.querySelectorAll('table:not(.not-scrollable):not(.c-responsive-table)'));

	// Make some tables adapt better to narrow viewports
	context.querySelectorAll('.c-responsive-table').forEach(
		(table) =>
			new ResponsiveTable({
				element: table,
			})
	);
});

document.dispatchEvent(new CustomEvent('newcontent'));