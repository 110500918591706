import extend from '../extend/u-extend.js';
import serialize from '../serialize/u-serialize.js';

class Ajax {
	/**
	 * [constructor description]
	 * @param  {Object} settings Usersettings
	 */
	constructor (settings = {}) {
		// Default settings
		let defaults = {
			method: 'GET',
			url: false,
			dataType: 'raw',
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			},
			data: null,
			onSuccess: () => {},
			onError: () => {},
			onComplete: () => {}
		};

		// Merge user settings onto default settings
		this.settings = extend(true, defaults, settings);
	}

	/**
	 * Add header to XHR-request
	 * @param {String} key Header key
	 * @param {String} value  Header value
	 */
	addHeader (key, value = false) {
		this.settings.headers[key] = value;
	}

	/**
	 * Remove header from XHR-request
	 * @param  {[type]} key Header key to remove
	 */
	removeHeader (key) {
		delete this.settings.headers[key];
	}

	/**
	 * Start request and return Promise
	 * @return {Promise}
	 */
	send () {
		// Return error if no URL is set
		if (!this.settings.url) {
			return new Error('No URL is specified');
		}

		// Start XHR request
		return new Promise((resolve, reject) => {
			this._request(resolve, reject);
		});
	}

	/**
	 * Make the XHR-reqyest
	 * @param  {Object} resolve Promise resolve
	 * @param  {Object} reject  Promise reject
	 */
	_request (resolve, reject) {
		// Create XHR instance
		this.request = new XMLHttpRequest();

		let url = this.settings.url;
		let data = null;

		// Parse data and setting headers
		if (this.settings.data) {
			switch (this.settings.method) {
				case 'POST':
					this.addHeader('Content-Type', 'application/x-www-form-urlencoded');
					data = serialize(this.settings.data);
					break;
				default:
					url = url + '?' + serialize(this.settings.data);
			}
		}

		// Open XHR request
		this.request.open(this.settings.method, url, true);

		// Set request headers
		this._setHeaders();

		this.request.onreadystatechange = () => {
			if (this.request.readyState === 4) {
				if (this.request.status === 200) {
					this._onLoad(resolve, reject);
				}
				else {
					this._onError(reject);
				}
			}
		};

		// Send XHR request
		this.request.send(data);
	}

	/**
	 * Set added request headers to XHR instance
	 */
	_setHeaders () {
		for (let header in this.settings.headers) {
			if (header && this.settings.headers[header]) {
				this.request.setRequestHeader(header, this.settings.headers[header]);
			}
		}
	}

	/**
	 * Handle XHR-response
	 * @param  {Object} resolve Promise resolve
	 * @param  {Object} reject  Promise reject
	 */
	_onLoad (resolve, reject) {
		// Reject Promise with error if statuscode isn't 200
		if (this.request.status !== 200) {
			// Create a error
			this.error = new Error(`${this.request.status} ${this.request.statusText}`);

			// Call on error callback
			if (typeof this.settings.onComplete === 'function') {
				this.settings.onError(this.error, this);
			}

			// Call on complete callback
			if (typeof this.settings.onComplete === 'function') {
				this.settings.onComplete(this.error, this);
			}

			// Reject Promise
			reject(this.error);
		}

		// Format response
		let response = this._formatResponse();

		// Call on success callback
		if (typeof this.settings.onSuccess === 'function') {
			this.settings.onSuccess(response, this);
		}

		// Call on complete callback
		if (typeof this.settings.onComplete === 'function') {
			this.settings.onComplete(response, this);
		}

		// Resolve Promies with formated response
		resolve(response);
	}

	/**
	 * Handle XHR-error
	 * @param  {Object} reject  Promise reject
	 */
	_onError (reject) {
		// Create a error
		this.error = new Error(`${this.request.status} ${this.request.statusText}`);

		// Call on error callback
		if (typeof this.settings.onError === 'function') {
			this.settings.onError(this.error, this);
		}

		// Call on complete callback
		if (typeof this.settings.onComplete === 'function') {
			this.settings.onComplete(this.error, this);
		}

		// Reject Promise on XHR error
		reject(this.error);
	}

	/**
	 * Format the XHR response
	 * @return {Mixed} Formated response
	 */
	_formatResponse () {
		// Return format depending on dataType
		switch (this.settings.dataType) {
			case 'json':
				return this._formatJSON(this.request.responseText);
		}
		return this.request.responseText;
	}

	/**
	 * [_formatJSON description]
	 * @param  {String} data JSON string
	 * @return {Mixed}
	 */
	_formatJSON (data) {
		try {
			return JSON.parse(data);
		}
		catch (e) {
			return new Error('JSON parse error');
		}
	}
}

export default function (settings = {}) {
	let request = new Ajax(settings);

	return request.send();
}