import extend from '../utilities/extend/u-extend.js';

export default class Component {
	constructor (settings = {}) {
		this.settings = extend(true, this._defaultSettings(), settings);

		this._init();
	}
	_defaultSettings () {
		return {
			debug: false
		};
	}
	_init () {}
	_log (message = '') {
		if (!this.settings.debug) {
			return;
		}
		console.log(message);
	}
}