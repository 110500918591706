export default class CustomSelect {
	handleFieldset() {
		const customSelect = document.querySelector('.c-custom-select__role');
		const hideFieldSets = document.querySelectorAll('.c-form__fieldset--role');

		for (const index of hideFieldSets.keys()) {
			showFieldSets(index);
			customSelect.addEventListener('change', function (e) {
				e.preventDefault();
				showFieldSets(index);
			});
		}

		function showFieldSets(index) {
			let optData = customSelect.options[customSelect.selectedIndex].dataset.ts_toggle_target;
			optData === hideFieldSets[index].getAttribute('id') ?
				hideFieldSets[index].style.display = 'block' :
				hideFieldSets[index].style.display = 'none';
		}

	}
}
