import Glide from '@glidejs/glide';

// get the dashboard carousel
const dashboardCarousel = document.querySelectorAll('.glide__dashboard');
dashboardCarousel.forEach((item) => {
	// get the data attribute for setting the speed
	const glideSpeed = document.querySelector('[data-glide-board-speed]');
	const dashboardSlider = new Glide(item, {
		// set the autoplay speed for the dashboard through the data attribute
		autoplay: glideSpeed.dataset.glideBoardSpeed,
		animationDuration: 2000,
		hoverpause: false,
		type: 'carousel',
	});

	// mount it only if a it is a dashboard page
	if(document.body.classList.contains('dashboard')) {
		dashboardSlider.mount();
	}
});

// get the carousels inside of the dashboard
const itemCarousel = document.querySelectorAll('.glide__dashboard--item');
// check if it exists
if(itemCarousel.length !== 0) {
	// loop thorugh every child carousel if its more than 1
	itemCarousel.forEach((item, index) => {
		const glideSpeed = document.querySelectorAll('[data-glide-item-speed]');
		const productSlider = new Glide(item, {
			// give every child carousel its own autoplay speed
			autoplay: glideSpeed[index].dataset.glideItemSpeed,
			animationDuration: 1000,
			hoverpause: false,
			type: 'carousel'
		});

		// mount it only if a it is a dashboard page
		if(document.body.classList.contains('dashboard')) {
			productSlider.mount();
		}
	});
}

